import { PersonOutlineOutlined } from "@material-ui/icons";
import Image from "next/image";
import { classNames } from "../utils/classNames";
import { getImgixUrlWithParams } from "../utils/images";

export default function ProfileImage(props: {
  imageRadius: number;
  imageUrl?: string | null;
  placeholderClassName?: string;
}) {
  const { imageRadius, imageUrl, placeholderClassName } = props;

  return imageUrl ? (
    <Image
      src={getImgixUrlWithParams(imageUrl, {
        w: imageRadius,
        h: imageRadius,
        mask: "ellipse",
        fit: "crop",
        dpr: 2,
      })}
      width={imageRadius}
      height={imageRadius}
    />
  ) : (
    <div
      className={classNames(
        "h-" + imageRadius / 4,
        "w-" + imageRadius / 4,
        "flex",
        "flex-shrink-0",
        "flex-grow-0",
        "items-center",
        "justify-center",
        "bg-gray-100",
        "rounded-full",
        placeholderClassName ? placeholderClassName : ""
      )}
    >
      <PersonOutlineOutlined style={{ fontSize: 20 }} />
    </div>
  );
}

import { getSession } from "next-auth/client";
import { apiUrls, fetcher } from "./api";
import { Building, PaginatedBuildings, BuildingRating } from "../types/api";
import { getTenantFullName } from "./tenants";
import { filterManagerUnits } from "./units";
import { weightedMean } from "./weightedMean";

export const getAllBuildings = async (url: string): Promise<Building[]> => {
  const session = await getSession();
  const data = await fetcher(url, session);

  if (data && data.next) {
    return data.results
      ? data.results.concat(await getAllBuildings(data.next))
      : [];
  } else {
    return data.results ? data.results : [];
  }
};

/* DEPRECATED: remove if not needed anywhere else */
// export function getNumFloors(building: Building): number {
//   const floors: number[] = filterManagerUnits(building.units)
//     .map((u) => u.floor)
//     .filter((f): f is number => Number.isInteger(f));

//   return new Set(floors).size;
// }

export function getUniqueBuildingFloors(buildings: Building[]): number[] {
  // map over all buildings and filter out any null or undefined values
  //   if a unit doesn't have a floor
  const floors: number[] = buildings
    .map((b) => b.units)
    .map((units) =>
      filterManagerUnits(units)
        .map((unit) => unit.floor)
        .filter((f) => f !== null)
        .filter((f) => f !== undefined)
    )
    .reduce((acc, c) => acc.concat(c), [])
    .sort((f1, f2) => f1 - f2);
  return Array.from(new Set(floors));
}

/* DEPRECATED: remove if not needed anywhere else */
// export function getNumResidents(building: Building): number {
//   const residents: number = filterManagerUnits(building.units)
//     .map((u) => u.tenants.length)
//     .reduce((acc, c) => acc + c, 0);
//   return residents;
// }

export function getAllResidentsNamesWithGrapheneId(
  building: Building
): { id: string; display: string }[] {
  const residentsNames: { id: string; display: string }[] = filterManagerUnits(
    building.units
  )
    .map((u) => u.tenants)
    .reduce((acc, c) => acc.concat(c), [])
    .map((t) => ({ id: t.user.graphene_id, display: getTenantFullName(t) }));
  return residentsNames;
}

/* DEPRECATED: remove if not needed anywhere else */
// export function getPercentOccupied(building: Building): number {
//   const units = filterManagerUnits(building.units);
//   if (units.length === 0) {
//     return 0;
//   }

//   const occupiedUnits = units.filter((u) => u.tenants.length);
//   const occupiedPerc = (occupiedUnits.length / units.length) * 100;
//   return Math.round(occupiedPerc);
// }

export function getRatingWeightedMean(building: Building): number | null {
  const ratings = Object.values<BuildingRating>(building.ratings).filter(
    (rating) => rating.status === "OK"
  );

  if (!ratings.length) {
    return null;
  }

  const values = ratings.map((r) => r.rating!);
  const weights = ratings.map((r) => r.user_ratings_total!);

  return Math.round(weightedMean(values, weights) * 100) / 100;
}

export function getRatingSources(building: Building): string[] {
  return Object.entries<BuildingRating>(building.ratings)
    .filter(([_, rating]) => rating.status === "OK")
    .map(([service, _]) => service);
}

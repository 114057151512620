import { Unit } from "../types/api";

/* DEPRECATED: remove if not needed anywhere else */
// export function sortUnitsByFloorAndNumber(units: Unit[]): Unit[] {
//   return units.sort((a, b) => {
//     // unit number could be parsed as an int
//     const numberA = parseInt(a.number);
//     const numberB = parseInt(b.number);

//     if (!isNaN(numberA) && !isNaN(numberB)) {
//       if (numberA < numberB) {
//         return -1;
//       }
//       if (numberA > numberB) {
//         return 1;
//       }
//     }
//     // unit number exists but can't be parsed as an int
//     else {
//       const numberStringA = a.number.toUpperCase();
//       const numberStringB = b.number.toUpperCase();

//       if (numberStringA < numberStringB) {
//         return -1;
//       }
//       if (numberStringA > numberStringB) {
//         return 1;
//       }
//     }

//     // unit numbers are equal, rely on floor
//     if (a.floor != null && b.floor != null) {
//       if (a.floor < b.floor) {
//         return -1;
//       }
//       if (a.floor > b.floor) {
//         return 1;
//       }
//     }

//     return 0;
//   });
// }

// This is a temporary solution for Managers to be able to access the tenant
// application. It filters out units that are numbered "Managers", which ops
// will do when PMs want to view the mobile app but aren't actual tenants of the
// building. This should be chained into all units calls when displaying or
// rolling up floor data
export function filterManagerUnits(units: Unit[]): Unit[] {
  return units.filter((u) => u.number.toLowerCase() !== "managers");
}

export function getImgixUrlWithParams(
  url: string,
  params: { [key: string]: string | number | null }
): string {
  const urlParams = Object.entries(params)
    .map((entry) => {
      const [k, v] = entry;
      return k + (v ? `=${v}` : "");
    })
    .join("&");

  return `${url}?${urlParams}`;
}

import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  CheckCircleOutlined,
  CloseOutlined,
  EventOutlined,
  ForumOutlined,
  HomeWorkOutlined,
  HowToVote,
  LinkOutlined,
  LiveHelpOutlined,
  MenuOutlined,
  MessageOutlined,
  PersonOutlineOutlined,
  SettingsOutlined,
  SupervisedUserCircleOutlined,
  VerifiedUserOutlined,
} from "@material-ui/icons";
import axios from "axios";
import { signOut, useSession } from "next-auth/client";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { cloneElement, Fragment, useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import { apiUrls, authHeaders, deleteAuthHeaders, fetcher } from "../utils/api";
import { classNames } from "../utils/classNames";
import AddResidentModal from "./AddResidentModal";
import { Button } from "./Button";
import { ContextMenu } from "./ContextMenu";
import {
  loadIntercom,
  showIntercomWindow,
  updateIntercom,
} from "next-intercom";
import { useModals } from "../hooks/useModals";
import { useUser } from "../hooks/useUser";
import { Conversation, PaginatedConversations } from "../types/api";
import { sub } from "date-fns";
import ConversationContext from "./ConversationContext";
import { getUserFullName } from "../utils/users";
import ProfileImage from "./ProfileImage";
import { timeSince } from "../utils/dates";

interface NavigationItem {
  name: string;
  href: string;
  icon: any;
}

const navigation: NavigationItem[] = [
  { name: "Properties", href: "/properties", icon: HomeWorkOutlined },
  { name: "Residents", href: "/residents", icon: SupervisedUserCircleOutlined },
  { name: "Events", href: "/events", icon: EventOutlined },
  { name: "Posts", href: "/posts", icon: ForumOutlined },
  { name: "Mod", href: "/mod", icon: VerifiedUserOutlined },
  { name: "Surveys", href: "/surveys", icon: HowToVote },
  { name: "Messages", href: "/messages", icon: MessageOutlined },
  { name: "Settings", href: "/settings", icon: SettingsOutlined },
];

export function AppHead({ title = "Sugar Portal" }: { title?: string }) {
  return (
    <Head>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>
  );
}

export default function Layout({
  children,
  title = "Sugar Portal",
}: {
  children: React.ReactNode;
  title?: string;
}) {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [addResidentModalOpen, setAddResidentModalOpen] =
    useState<boolean>(false);
  const [intercomLoaded, setIntercomLoaded] = useState<boolean>(false);
  const router = useRouter();
  const activeRoute = router.pathname;
  const [session, loading] = useSession();
  const [recentConversations, setRecentConversations] = useState<
    Conversation[]
  >([]);
  const { openConversationPaneWithConversation } = useModals();
  const user = useUser();

  // API calls
  const {
    data: paginatedConversations,
    error,
    mutate: mutateConversations,
  } = useSWR<PaginatedConversations>(apiUrls.conversations, fetcher, {
    refreshInterval: 20000,
  });

  const profileImageSrc: string | undefined =
    user && user.profile?.profile_image
      ? user.profile.profile_image
      : session?.user?.image || undefined;

  // load intercom on page load
  useEffect(() => {
    if (!user) {
      return;
    }
    if (!intercomLoaded && process.env.NEXT_PUBLIC_INTERCOM_APP_ID) {
      loadIntercom({
        appId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
        email: user.email,
        name: getUserFullName(user),
        ssr: false,
        initWindow: true,
        delay: 0,
      });
      updateIntercom("update", { hide_default_launcher: true });
    }
    setIntercomLoaded(true);
  }, [user]);

  // close the mobile sidebar when the add resident modal is opened
  useEffect(() => {
    if (addResidentModalOpen) {
      setSidebarOpen(false);
    }
  }, [addResidentModalOpen]);

  useEffect(() => {
    if (!paginatedConversations || !paginatedConversations.results) {
      return;
    }
    setRecentConversations(
      paginatedConversations.results.filter(
        (c) =>
          new Date(c.last_message_timestamp) > sub(new Date(), { weeks: 1 })
      )
    );
  }, [paginatedConversations]);

  if (
    ((!session && !loading) || session?.error === "RefreshAccessTokenError") &&
    typeof window !== "undefined"
  ) {
    router.push("/login?expired");
  }

  axios.defaults.headers.common = authHeaders(session?.accessToken || "");

  return (
    <div className="h-screen flex overflow-hidden bg-gray-50">
      <AppHead title={title} />
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-50">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <Button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <CloseOutlined
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </Button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <Image src="/logo.png" alt="Sugar" width={87} height={24} />
              </div>
              <div className="mt-5 flex-1 flex-col flex-grow h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <NavigationLink
                      key={item.name}
                      item={item}
                      activeRoute={activeRoute}
                    />
                  ))}
                </nav>
                <div className="mx-2 mt-4">
                  <Button
                    className="button-primary h-10 w-full text-sm font-bold"
                    disabled={user?.organization.sync_integration?.is_enabled}
                    onClick={() => setAddResidentModalOpen(true)}
                  >
                    Add Resident
                  </Button>
                </div>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-52">
          {/* Sidebar component */}
          <div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
            <div className="flex mb-4 items-center flex-shrink-0 px-4">
              <Image src="/logo.png" alt="Sugar" width={87} height={24} />
            </div>
            <div className="mt-5 flex-grow flex flex-col">
              <nav className="flex-1 px-2 space-y-1">
                {navigation.map((item) => (
                  <NavigationLink
                    key={item.name}
                    item={item}
                    activeRoute={activeRoute}
                  />
                ))}
              </nav>
              <div className="mx-2">
                {user?.organization.sync_integration?.is_enabled ? (
                  <div className="flex items-center px-3 py-1 bg-green-100 rounded-xl">
                    <Image
                      src={`/integration_appfolio.png`}
                      width="16"
                      height="16"
                    />
                    <div className="pl-1.5 text-sm font-medium">
                      {user?.organization.sync_integration?.software_type}
                    </div>
                    {cloneElement(
                      <CheckCircleOutlined className="text-green-700 ml-auto" />,
                      {
                        style: { fontSize: 20 },
                      }
                    )}
                  </div>
                ) : (
                  <Button
                    className="button-primary h-10 w-full text-sm font-bold"
                    disabled={user?.organization.sync_integration?.is_enabled}
                    onClick={() => setAddResidentModalOpen(true)}
                  >
                    Add Resident
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* App section */}
      <div className="flex flex-col w-0 flex-1 overflow-hidden border bg-white md:border-gray-200 md:rounded-l-xl">
        {/* search bar */}
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200">
          <Button
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuOutlined className="h-6 w-6" aria-hidden="true" />
          </Button>
          <div className="flex-1 px-4 flex justify-between">
            <div className="flex-1 flex">
              {/* <form className="w-full flex md:ml-0" action="#" method="GET">
                <label htmlFor="search_field" className="sr-only">
                  Search
                </label>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    id="search_field"
                    className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                    placeholder="Search"
                    type="search"
                    name="search"
                  />
                </div>
              </form> */}
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              {/* messages context menu */}
              <ContextMenu
                menuButton={
                  <Menu.Button className="relative flex items-center justify-center h-8 w-8 cursor-pointer bg-white rounded-full mr-4 text-gray-700 hover:bg-gray-100">
                    <span className="sr-only">Messages</span>
                    <MessageOutlined style={{ fontSize: 20 }} />
                  </Menu.Button>
                }
                className="md:!w-96"
              >
                {recentConversations.length > 0 ? (
                  <>
                    <div className="px-3 py-2 text-sm font-semibold bg-gray-50">
                      This week's messages
                    </div>
                    {recentConversations.map((c) => {
                      const recipient = c.recipients[0];
                      return (
                        <div
                          key={c.id}
                          className="context-menu-link flex items-center border-t border-gray-50"
                          onClick={() =>
                            openConversationPaneWithConversation(c)
                          }
                        >
                          <div className="flex-shrink-0 flex-grow-0 w-6 mr-4">
                            <ProfileImage
                              imageUrl={recipient.profile.profile_image}
                              imageRadius={24}
                            />
                          </div>
                          <div className="">
                            <div className="text-sm font-semibold text-current overflow-hidden overflow-ellipsis whitespace-nowrap">
                              {getUserFullName(recipient)}
                            </div>
                            <ConversationContext conversation={c} />
                          </div>
                          <div className="ml-auto font-normal text-gray-500">
                            {timeSince(c.last_message_timestamp)}
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="context-menu-link text-gray-500 cursor-default hover:bg-white">
                    No new messages!
                  </div>
                )}

                <Link href="/messages">
                  <a className="context-menu-link border-t border-gray-100">
                    View All Messages
                  </a>
                </Link>
              </ContextMenu>
              <div
                onClick={() => {
                  showIntercomWindow();
                }}
                className="flex items-center justify-center h-8 w-8 cursor-pointer bg-white rounded-full text-gray-700 hover:bg-gray-100 mr-4"
              >
                <span className="sr-only">Contact Support</span>
                <LiveHelpOutlined style={{ fontSize: 20 }} />
              </div>

              {/* Profile dropdown */}
              <ContextMenu
                menuButton={
                  <Menu.Button className="flex items-center justify-center h-8 w-8 bg-white rounded-full text-sm focus:outline-none focus:ring focus:border-blue-300">
                    <span className="sr-only">Open user menu</span>
                    <span className="inline-flex items-center justify-center h-6 w-6 rounded-full overflow-hidden bg-gray-500">
                      {profileImageSrc ? (
                        <img src={profileImageSrc} className="w-full" />
                      ) : (
                        <div className="h-8 w-8 flex flex-shrink-0 flex-grow-0 items-center justify-center bg-yellow-100 rounded-full">
                          <PersonOutlineOutlined
                            style={{ fontSize: 22 }}
                            className="text-yellow-800"
                          />
                        </div>
                      )}
                    </span>
                  </Menu.Button>
                }
              >
                <Link href="/profile">
                  <a
                    className={classNames(
                      activeRoute === "/profile" ? "bg-gray-50" : "",
                      "context-menu-link"
                    )}
                  >
                    Profile
                  </a>
                </Link>
                <div
                  className="context-menu-link"
                  onClick={async () => {
                    const data = await signOut({
                      redirect: false,
                      callbackUrl: `${window?.location.origin || ""}/login`,
                    });
                    deleteAuthHeaders();
                    // @ts-ignore
                    router.push(data.url);
                  }}
                >
                  Logout
                </div>
              </ContextMenu>
            </div>
          </div>
        </div>

        <main className="relative flex flex-col flex-1 pt-2 overflow-hidden focus:outline-none">
          {children}
        </main>
      </div>
      {/* TODO: remove all other AddResidentModal's and trigger this one from layout children */}
      <AddResidentModal
        open={addResidentModalOpen}
        onClose={(shouldRevalidate, buildingId) => {
          setAddResidentModalOpen(false);

          // revalidate based on the active page
          // if in `properties`, refresh the building
          // if in `residents`, refresh the total residents list
          if (shouldRevalidate) {
            if (activeRoute.includes("properties") && buildingId) {
              mutate(apiUrls.building(buildingId.toString()));
            }
            if (activeRoute.includes("residents")) {
              mutate(apiUrls.tenants);
            }
          }
        }}
      />
    </div>
  );
}

function NavigationLink(props: { item: NavigationItem; activeRoute: string }) {
  const { item, activeRoute } = props;

  return (
    <Link href={item.href} key={item.name}>
      <a
        className={classNames(
          activeRoute.includes(item.href)
            ? "bg-white text-gray-900"
            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
          "group h-10  p-4 flex items-center rounded-xl text-sm font-semibold leading-none"
        )}
      >
        <item.icon
          className={classNames(
            activeRoute.includes(item.href)
              ? "text-blue-400"
              : "text-gray-600 group-hover:text-gray-900",
            "mr-2 h-6 w-6"
          )}
          style={{ fontSize: 22 }}
          aria-hidden="true"
        />
        <span className="flex-grow pt-[1px]">{item.name}</span>
        <div
          className={classNames(
            activeRoute.includes(item.href) ? "visible" : "invisible",
            "bg-blue-400 w-1.5 h-1.5 rounded-full"
          )}
        ></div>
      </a>
    </Link>
  );
}

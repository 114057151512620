import { Menu, Transition } from "@headlessui/react";
import { MoreHorizOutlined } from "@material-ui/icons";
import React, { Fragment } from "react";
import { classNames } from "../utils/classNames";

export function ContextMenu(props: {
  children: React.ReactNode;
  menuButton?: React.ReactNode;
  className?: string;
}) {
  return (
    <Menu as="div" className="relative flex justify-end">
      {({ open }) => (
        <>
          {props.menuButton ? (
            <Menu.Button
              as="div"
              className={classNames(
                open ? "drop-shadow-md" : "",
                "cursor-pointer"
              )}
            >
              {props.menuButton}
            </Menu.Button>
          ) : (
            <Menu.Button
              as="div"
              className={classNames(
                open ? "bg-blue-100" : "",
                "button cursor-pointer w-8 max-w-xs"
              )}
            >
              <MoreHorizOutlined style={{ fontSize: 24 }} />
            </Menu.Button>
          )}
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className={classNames(
                props.className || "",
                "fixed left-0 bottom-0 right-0 pb-4 shadow-2xl md:pb-0 md:origin-top-right md:absolute md:right-0 md:left-auto md:bottom-auto md:top-10 md:w-56 rounded-md md:shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30"
              )}
            >
              {props.children}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

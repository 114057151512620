import { Building, NestedTenant, Tenant } from "../types/api";
import UniqueArray from "./uniqueArray";
import { getUserFullName } from "./users";

export function getTenantFullName(tenant: Tenant | NestedTenant): string {
  return getUserFullName(tenant.user);
}

export function getTenantBirthdayMonth(tenant: Tenant | NestedTenant): string {
  return tenant.user.profile.birth_date?.split("-")[1] || "";
}

export function hasCars(tenant: Tenant | NestedTenant): boolean {
  return tenant.user.profile.cars?.length !== 0;
}

export function hasPets(tenant: Tenant): boolean {
  return tenant.user.profile.pets?.length !== 0;
}

/* DEPRECATED: remove if not needed anywhere else */
// export function getUniqueBuildingsFromTenants(tenants: Tenant[]): Building[] {
//   const buildings: Building[] = tenants.map((t) => t.unit.building);
//   return UniqueArray<Building>(buildings);
// }

/* DEPRECATED: remove if not needed anywhere else */
// export function getUniqueBuildingFloorsFromTenants(tenants: Tenant[]): number[] {
//   // map over all tenants and filter out any null or undefined values if a unit doesn't have a floor
//   const floors: number[] =
//     tenants.map((t) => t.unit.floor)
//       .filter((f) => f !== null)
//       .filter((f) => f !== undefined)
//       .sort((f1, f2) => f1 - f2);
//   return Array.from(new Set(floors));
// }

export function isActive(tenant: Tenant): boolean {
  if (!tenant.move_out_date) {
    return true;
  }

  return new Date(tenant.move_out_date) > new Date();
}

import { Switch } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { format } from "date-fns";
import Link from "next/link";
import { cloneElement, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import useSWR from "swr";
import * as yup from "yup";
import "yup-phone";
import { useUser } from "../hooks/useUser";
import { apiUrls, fetcher } from "../utils/api";
import { getAllBuildings } from "../utils/buildings";
import { classNames } from "../utils/classNames";
import { dateStringtoDate, toISOStringTimeZone } from "../utils/dates";
import getImageForDoorType, { getAllUnitDoors } from "../utils/doors";
import { filterManagerUnits } from "../utils/units";
import {
  Building,
  Door,
  PaginatedBuildings,
  PaginatedDoors,
  Tenant,
  Unit,
} from "../types/api";
import { Button } from "./Button";
import { ButtonIcon } from "./ButtonIcon";
import Modal from "./Modal";
import { segmentTrack } from "../lib/segment";
import { showIntercomWindow } from "next-intercom";

const phoneSchema = yup
  .string()
  .phone("US", true, "must be a valid phone number for region US")
  .required("Phone number is required");

const schema = yup.object().shape(
  {
    user_email: yup.string().when("user_phone_number", {
      is: (phone) => !phone || phone.length === 0,
      then: yup
        .string()
        .required("Email is required")
        .email("Email is invalid"),
      otherwise: yup.string().email("Email is invalid"),
    }),
    user_phone_number: yup.string().when("user_email", {
      is: (email) => !email || email.length === 0,
      then: phoneSchema,
      otherwise: yup
        .string()
        .test((value) => !value || phoneSchema.isValid(value)),
    }),
    user_first_name: yup.string().required("First name is required"),
    user_last_name: yup.string().required("Last name is required"),
    unit_id: yup.number().required(),
    building_id: yup.number().required(),
    move_in_date: yup
      .date()
      .required("Move in date is required")
      .typeError("Invalid Date"),
    move_out_date: yup
      .date()
      .nullable()
      .typeError("Invalid Date")
      .transform((curr, orig) => (orig === "" ? null : curr)),
  },
  [["user_email", "user_phone_number"]]
);

type AddResidentInputs = yup.InferType<typeof schema>;

export default function AddResidentModal(props: {
  open: boolean;
  selectedBuilding?: Building;
  selectedUnit?: Unit;
  onClose: (shouldRevalidate: boolean, buildingId?: number) => void;
}) {
  const { open, selectedBuilding, selectedUnit, onClose } = props;
  const user = useUser();
  const { addToast } = useToasts();
  const {
    handleSubmit,
    register,
    reset,
    setError,
    watch,
    setValue,
    formState: { errors },
  } = useForm<AddResidentInputs>({ resolver: yupResolver(schema) });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [buildings, setBuildings] = useState<Building[]>([]);
  const [units, setUnits] = useState<Unit[] | null>(null);
  const [usingExistingTenantDates, setUsingExistingTenantDates] = useState<{
    move_in_date: boolean;
    move_out_date: boolean;
  }>({
    move_in_date: false,
    move_out_date: false,
  });
  let initialFocus = useRef(null);
  const building_id = watch("building_id");
  const unit_id = watch("unit_id");

  // if a selected building wasn't passed through, grab the
  // available buildings
  // get a list of all the Buildings
  useEffect(() => {
    const getBuildings = async () => {
      const buildings: Building[] = await getAllBuildings(
        `${apiUrls.buildings}?fields=id,name`
      );
      setBuildings(buildings);
    };
    if (user && !selectedBuilding) {
      getBuildings();
    }

    return () => {
      setBuildings([]);
    };
  }, []);

  let doors: Door[] | null = null;
  const [commonDoors, setCommonDoors] = useState<Door[] | null>(null);
  const [accessibleDoors, setAccessibleDoors] = useState<Door[] | null>(null);
  const [selectedUnitIdDoors, setSelectedUnitIdDoors] = useState<Door[] | null>(
    null
  );
  type DoorAccessResidentCreate = { id: number; is_active: boolean };
  const [doorAccesses, setDoorAccesses] = useState<DoorAccessResidentCreate[]>(
    []
  );
  const {
    data: buildingDoors,
    error: buildingDoorsError,
    mutate: buildingDoorsMutate,
  } = useSWR<PaginatedDoors>(
    building_id
      ? apiUrls.buildingDoors(building_id.toString())
      : selectedBuilding
      ? apiUrls.buildingDoors(selectedBuilding.id.toString())
      : null,
    fetcher
  );
  if (buildingDoors && buildingDoors.results) {
    doors = buildingDoors?.results;
  }

  // set units if specified on is not passed in
  useEffect(() => {
    if (buildings && buildings.length && !selectedBuilding) {
      let building: Building | null = null;
      if (building_id) {
        // TODO: for some reason building_id isn't a number when running this check
        //@ts-ignore
        building = buildings.filter((b) => b.id === parseInt(building_id))[0];
      }
      if (building) {
        const building_with_units = async () => {
          const buildingUnitsData = await axios.get<Unit[]>(
            apiUrls.buildingUnits(building_id.toString())
          );
          if (unit_id) {
            setValue("unit_id", null);
          }
          setUnits(
            filterManagerUnits(buildingUnitsData.data).sort((u1, u2) =>
              u1.number.localeCompare(u2.number)
            )
          );
        };

        building_with_units();
        setUsingExistingTenantDates({
          move_in_date: false,
          move_out_date: false,
        });
        setValue("move_in_date", null);
        setValue("move_out_date", null);
      }
    }
    return () => {};
  }, [building_id, buildings]);

  // ensure unit_id is properly set in react-hook-form
  useEffect(() => {
    let unit =
      selectedUnit ||
      //@ts-ignore
      (units ? units.filter((u) => u.id === parseInt(unit_id))[0] : null);
    if (unit) {
      setValue("unit_id", unit.id.toString());
    }
    return () => {};
  }, [selectedUnit, unit_id]);

  // update default move in / out dates
  useEffect(() => {
    let tenant_move_in_date = null,
      tenant_move_out_date = null;

    // no unit is set (via passed in or selected)
    if (!selectedUnit && !unit_id) {
      setUsingExistingTenantDates({
        move_in_date: false,
        move_out_date: false,
      });
    } else {
      let unit =
        selectedUnit ||
        //@ts-ignore
        (units ? units.filter((u) => u.id === parseInt(unit_id))[0] : null);

      // no unit was selectable, or no tenants currently exist
      if (!unit || !unit.tenants || unit.tenants.length === 0) {
        setUsingExistingTenantDates({
          move_in_date: false,
          move_out_date: false,
        });
        setValue("move_in_date", null);
        setValue("move_out_date", null);
      } else {
        const tenant = unit.tenants[0] || unit.tenants;

        setUsingExistingTenantDates({
          move_in_date:
            tenant.move_in_date !== null && tenant.move_in_date !== undefined,
          move_out_date:
            tenant.move_out_date !== null && tenant.move_out_date !== undefined,
        });

        setValue(
          "move_in_date",
          tenant.move_in_date
            ? format(dateStringtoDate(tenant.move_in_date), "yyyy-MM-dd")
            : null
        );

        setValue(
          "move_out_date",
          tenant.move_out_date
            ? format(dateStringtoDate(tenant.move_out_date), "yyyy-MM-dd")
            : null
        );
      }
    }
  }, [selectedUnit, unit_id]);

  useEffect(() => {
    const getUnitDoors = async () => {
      const doors: Door[] = await getAllUnitDoors(
        `${apiUrls.buildingDoors(
          building_id.toString()
        )}?door_type=UNIT&unit_id=${unit_id}`
      );
      setSelectedUnitIdDoors(doors);
    };

    if (building_id && unit_id) {
      getUnitDoors();
    }

    return () => {
      setSelectedUnitIdDoors(null);
    };
  }, [unit_id]);

  useEffect(() => {
    if (doors) {
      const common: Door[] = doors.filter((d) => d.door_type !== "UNIT");
      const unit: Door[] =
        units && unit_id
          ? selectedUnitIdDoors ?? []
          : selectedUnit
          ? selectedUnit.doors
          : [];

      let d: Door[] = [];
      switch (true) {
        case common.length > 0 && unit.length > 0:
          d = [...unit, ...common];
          break;
        case common.length > 0 && unit.length <= 0:
          d = [...common];
          break;
        case common.length <= 0 && unit.length > 0:
          d = [...unit];
          break;
        default:
          d = [];
          break;
      }
      setAccessibleDoors(d);
      const newDoorAccesses: DoorAccessResidentCreate[] = d.map((door) => ({
        id: door.id,
        is_active: door.enable_new_tenant || false,
      }));
      setDoorAccesses(newDoorAccesses);
    } else {
      setAccessibleDoors(null);
    }

    return () => {};
  }, [
    buildingDoors,
    doors,
    selectedUnitIdDoors,
    units,
    building_id,
    unit_id,
    selectedBuilding,
    selectedUnit,
  ]);

  const shouldAllowDoorAccess = (door: Door): boolean => {
    return doorAccesses.filter((d) => d.id == door.id)[0].is_active;
  };

  const onSubmit = async (data: AddResidentInputs) => {
    setIsLoading(true);

    try {
      await axios.post<Tenant>(apiUrls.tenants, {
        ...data,
        move_in_date: toISOStringTimeZone(data.move_in_date).split("T")[0],
        move_out_date:
          data.move_out_date &&
          toISOStringTimeZone(data.move_out_date).split("T")[0],
        door_accesses: doorAccesses,
      });
      addToast("Resident added!", { appearance: "success" });
      reset();
      segmentTrack("Add Tenant", {
        buildingId: building_id,
      });
      onClose(true, parseInt(building_id.toString()));
    } catch (error) {
      // something has gone really wrong if the error does not contain an API response
      if (!error.response) {
        addToast(
          "Well, shoot. Something went wrong. Try again or contact support!",
          { appearance: "error" }
        );
      }

      let errors: AddResidentInputs = error.response.data;
      Object.keys(errors).forEach((key) => {
        setError(key as keyof AddResidentInputs, {
          type: "server",
          message: error.response.data[key],
        });
      });
    }
    setIsLoading(false);
  };

  return (
    <Modal
      open={open}
      title={<AddResidentTitle unit={selectedUnit} />}
      initialFocus={initialFocus}
    >
      <form ref={initialFocus} onSubmit={handleSubmit(onSubmit)}>
        {"user" in errors ? (
          <>
            <p className="text-sm font-bold text-red-500 mb-2">
              Ah! That resident is already actively assigned to a unit.
            </p>
            <p className="text-sm font-bold text-red-500 mb-2">
              <a
                onClick={() => showIntercomWindow()}
                className="cursor-pointer underline"
              >
                Chat with support
              </a>{" "}
              and we'll get it ironed out quickly.
            </p>
            {/* @ts-ignore */}
            {"tenants" in errors && errors.tenants.message.length ? (
              <p className="text-sm font-bold text-red-500 mb-6">
                Or {/* @ts-ignore */}
                <Link href={`/residents/${errors.tenants.message[0]}`}>
                  <a className="cursor-pointer underline">
                    {/* @ts-ignore */}
                    manage their current resident status.
                  </a>
                </Link>
              </p>
            ) : (
              "That tenant"
            )}{" "}
          </>
        ) : null}
        <div className="mb-6">
          <label htmlFor="user_email" className="field-label">
            Resident Email
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="user_email"
              autoComplete="email"
              readOnly={isLoading}
              className="field"
              {...register("user_email")}
              placeholder="ex: celine.dion@sugarliving.com"
            />
          </div>
          <p className="field-error">{errors.user_email?.message}</p>
        </div>
        <div className="mb-6">
          <label htmlFor="user_phone_number" className="field-label">
            Phone Number
          </label>
          <div className="mt-1">
            <input
              type="phone"
              id="user_phone_number"
              autoComplete="phone"
              readOnly={isLoading}
              className="field"
              {...register("user_phone_number")}
              placeholder="ex: +18002655328"
            />
          </div>
          <p className="field-error">{errors.user_phone_number?.message}</p>
        </div>
        <div className="mb-6 flex space-x-4">
          <div>
            <label htmlFor="user_first_name" className="field-label">
              First Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="user_first_name"
                autoComplete="email"
                readOnly={isLoading}
                className="field"
                {...register("user_first_name")}
                placeholder="ex: Celine"
              />
            </div>
            <p className="field-error">{errors.user_first_name?.message}</p>
          </div>
          <div>
            <label htmlFor="user_last_name" className="field-label">
              Last Name
            </label>
            <div className="mt-1">
              <input
                type="phone"
                id="user_last_name"
                readOnly={isLoading}
                autoComplete="phone"
                className="field"
                {...register("user_last_name")}
                placeholder="ex: Dion"
              />
            </div>
            <p className="field-error">{errors.user_last_name?.message}</p>
          </div>
        </div>
        {/* Building selection
          - if selectedBuilding was passed, use the id in a hidden field
          - otherwise create
        */}
        {selectedBuilding ? (
          <input
            type="hidden"
            id="building_id"
            {...register("building_id")}
            defaultValue={selectedBuilding?.id}
          />
        ) : (
          <div className="mb-6">
            <label htmlFor="building_id" className="field-label">
              Property
            </label>

            <select
              id="building_id"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              defaultValue=""
              {...register("building_id")}
            >
              <option value="" className="select disabled" disabled>
                None selected
              </option>
              {buildings
                ? buildings.map((building) => {
                    return (
                      <option key={building.id} value={building.id}>
                        {building.name}
                      </option>
                    );
                  })
                : "..."}
            </select>

            <p className="field-error">{errors.building_id?.message}</p>
          </div>
        )}
        {selectedUnit ? (
          <input
            type="hidden"
            id="unit_id"
            {...register("unit_id")}
            defaultValue={selectedUnit?.id}
          />
        ) : (
          <div className="mb-6">
            <label htmlFor="unit_id" className="field-label">
              Unit
            </label>

            <select
              id="unit_id"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              defaultValue=""
              {...register("unit_id")}
            >
              {building_id ? (
                <option value="" className="select disabled" disabled>
                  None selected
                </option>
              ) : (
                <option value="" className="select disabled" disabled>
                  Select a Property first
                </option>
              )}
              {units
                ? units.map((unit) => {
                    return (
                      <option key={unit.id} value={unit.id}>
                        Unit {unit.number}
                      </option>
                    );
                  })
                : "..."}
            </select>

            <p className="field-error">{errors.unit_id?.message}</p>
          </div>
        )}
        <div className="mb-6">
          <label htmlFor="move_in_date" className="field-label">
            Move-in{" "}
            {usingExistingTenantDates.move_in_date ? (
              <span className="text-gray-500">
                (Set by existing resident lease)
              </span>
            ) : null}
          </label>
          <input
            type="date"
            id="move_in_date"
            className="field mb-4 disabled:text-gray-500"
            {...register("move_in_date")}
            readOnly={usingExistingTenantDates.move_in_date || isLoading}
          />
          <p className="field-error">{errors.move_in_date?.message}</p>
        </div>
        <div className="mb-6">
          <label htmlFor="move_out_date" className="field-label">
            Move-out{" "}
            {usingExistingTenantDates.move_out_date ? (
              <span className="text-gray-500">
                (Set by existing resident lease)
              </span>
            ) : null}
          </label>
          <input
            type="date"
            id="move_out_date"
            className="field mb-4 disabled:text-gray-500"
            {...register("move_out_date")}
            readOnly={usingExistingTenantDates.move_out_date || isLoading}
          />
          <p className="field-error">{errors.move_out_date?.message}</p>
        </div>
        {(selectedBuilding || building_id) && (
          <div className="mb-6">
            <label htmlFor="door_accesses" className="field-label">
              Doorway Access
            </label>
            {accessibleDoors &&
              accessibleDoors.map((door) => {
                return (
                  <Switch.Group
                    as="div"
                    key={door.uuid}
                    className="flex items-center py-1"
                  >
                    <Switch.Label as="span" className="flex-grow" passive>
                      <span className="text-sm font-medium text-gray-900 flex flex-row items-center">
                        {cloneElement(getImageForDoorType(door.door_type), {
                          style: { fontSize: 20 },
                        })}
                        <span className="ml-2">{door.name}</span>
                      </span>
                    </Switch.Label>
                    <Switch
                      checked={shouldAllowDoorAccess(door)}
                      onChange={(val) => {
                        const newDoorAccesses = doorAccesses.map((d) => {
                          if (d.id === door.id) {
                            d.is_active = val;
                          }
                          return d;
                        });
                        setDoorAccesses(newDoorAccesses);
                      }}
                      className={classNames(
                        shouldAllowDoorAccess(door)
                          ? "bg-blue-500"
                          : "bg-gray-200",
                        "relative inline-flex flex-shrink-0 h-3 w-6 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
                      )}
                    >
                      <span className="sr-only">
                        Provide new tenant with door access
                      </span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          shouldAllowDoorAccess(door)
                            ? "translate-x-3"
                            : "translate-x-0",
                          "pointer-events-none inline-block h-2 w-2 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                );
              })}
            <p className="field-error">{errors.door_accesses?.message}</p>
          </div>
        )}

        <div className="flex justify-end pt-5">
          <Button
            className="button-outline mr-2"
            disabled={isLoading}
            onClick={() => {
              reset();
              onClose(false);
              setUnits(null);
            }}
          >
            Cancel
          </Button>
          <Button type="submit" className="button-primary" loading={isLoading}>
            Save Resident
          </Button>
        </div>
      </form>
    </Modal>
  );
}

function AddResidentTitle(props: { unit?: Unit }) {
  const { unit } = props;
  return (
    <div className="flex flex-col">
      <h3 className="mb-1 text-lg font-md font-semibold">
        Add a resident to Unit {unit?.number || ""}
      </h3>
      <p className="text-sm font-semibold text-gray-700">
        Assigning a resident to this unit will enable tenant within the move-in
        and move-out dates.
      </p>
    </div>
  );
}

import axios from "axios";
import {
  AllInbox,
  DeckOutlined,
  EventSeat,
  FitnessCenter,
  Home,
  HomeWork,
  LocalCarWash,
  MeetingRoom,
  NaturePeople,
  TheatersOutlined,
} from "@material-ui/icons";
import React from "react";
import { Door, PaginatedDoors, DoorTypeEnum } from "../types/api";
import { apiUrls } from "../utils/api";

/* DEPRECATED: remove if not needed anywhere else */
// export function splitBuildingDoorsType(
//   buildingDoors: Door[]
// ): [Door[], Door[]] {
//   let commonDoors: Door[] = [];
//   let unitDoors: Door[] = [];

//   if (buildingDoors === []) {
//     return [commonDoors, unitDoors];
//   }

//   buildingDoors.forEach((d) => {
//     if (d.door_type === "UNIT") {
//       unitDoors.push(d);
//     } else {
//       commonDoors.push(d);
//     }
//   });
//   return [commonDoors, unitDoors];
// }

export function formatDoorType(door: Door): string {
  return (
    door.door_type?.replace("_", " ").replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }) ?? ""
  );
}

export default function getImageForDoorType(
  doorType: DoorTypeEnum | undefined
): React.ReactElement {
  switch (doorType) {
    case "BUILDING_ENTRY":
      return <HomeWork />;
    case "OUTDOOR_ENTRY":
      return <NaturePeople />;
      break;
    case "UNIT":
      return <Home />;
    case "GYM":
      return <FitnessCenter />;
    case "LOUNGE":
      return <EventSeat />;
    case "MOVIE_THEATRE":
      return <TheatersOutlined />;
    case "ROOF":
      return <DeckOutlined />;
    case "STORAGE":
      return <AllInbox />;
    case "GARAGE":
      return <LocalCarWash />;
    default:
      return <MeetingRoom />;
  }
}

export const getAllUnitDoors = async (url: string): Promise<Door[]> => {
  const response = await axios.get<PaginatedDoors>(url);
  const data = response.data;

  if (data && data.next) {
    return data.results
      ? data.results.concat(await getAllUnitDoors(data.next))
      : [];
  } else {
    return data.results ? data.results : [];
  }
};

import { format, formatDistance, parseISO } from "date-fns";

export function getDateDisplayFromApiDateString(
  dateString: string | undefined | null
): string | null {
  if (!dateString) {
    return null;
  }

  return format(parseISO(dateString), "MMM d, y");
}

export function getTimeDisplayFromApiDateString(
  dateString: string | undefined | null
): string | null {
  if (!dateString) {
    return null;
  }

  return format(parseISO(dateString), "hh:mm aa");
}

export function getDateTimeDisplayFromApiDateString(
  dateString: string | undefined | null
): string | null {
  return `${getDateDisplayFromApiDateString(
    dateString
  )} @ ${getTimeDisplayFromApiDateString(dateString)}`;
}

export function timeSince(dateString: string) {
  return `${formatDistance(new Date(dateString), new Date())} ago`;
}

export function dateStringtoDate(dateString: string): Date {
  var tokens: string[] = dateString.split("-");
  // [0] is yyyy, [1] is MM, [2] is dd
  return new Date(
    parseInt(tokens[0]),
    parseInt(tokens[1]) - 1,
    parseInt(tokens[2])
  );
}

export function toISOStringTimeZone(date: Date): string {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, -1);
}

import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, MutableRefObject } from "react";
import { classNames } from "../utils/classNames";

export default function Modal(props: {
  open: boolean;
  title?: React.ReactNode;
  size?: "regular" | "large",
  className?: string;
  children: React.ReactNode;
  initialFocus?: MutableRefObject<HTMLElement | null>;
  onClose?: () => void;
}) {
  const { open, title, size = "regular", className, children, initialFocus, onClose } = props;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        initialFocus={initialFocus}
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => {
          onClose ? onClose() : null;
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-white bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={classNames(
              "inline-block align-center bg-white rounded-lg text-left overflow-hidden border border-gray-200 shadow-xl transform transition-all w-4/5 my-8 align-middle",
              size === "large" ? "w-8/12" : "w-[27rem]",
            )}
            >
              {title && 
                <Dialog.Title
                  as="div"
                  className="text-lg font-semibold bg-gray-0 text-gray-900 p-4"
                >
                  {title}
                </Dialog.Title>
              }
              {size === "regular" ?
                (<>
                  <div className="p-4">{children}</div>
                </>)
              :
                (<>{children}</>)
              }
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
